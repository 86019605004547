<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table v-else
        :items="this.data"
        :headers="dataHeader"
        class="elevation-1"
        dense>

            <template v-slot:top>
                <v-toolbar width="auto" height="auto" color="py-3" flat>
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3">
                            <v-row class="mb-3">
                                <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                    Minute Record
                                </v-toolbar-title>
                            </v-row>
                        </v-col>    
                    </v-row>
                </v-toolbar>
            </template>

            <template v-slot:item.Index="{item}">
                {{ data.indexOf(item)+1 }}
            </template>
        </v-data-table>
        
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        data:null,
        dataHeader:[
            {
                text:'#',
                value:'Index',
            },
            {
                text:'Updated By',
                value:'user_name',
            },
            {
                text:'Minute Before',
                value:'original_minute',
            },
            {
                text:'Minute Add',
                value:'minute',
            },
            {
                text:'Minute After',
                value:'remaining_minute',
            },
            {
                text:'Record Date',
                value:'created_at',
            },
            {
                text:'Remark',
                value:'remark'
            }
        ],
        isPending:false,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:null,
        },
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="fetchMinuteRecord"){
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchMinuteRecordApi = this.fetchMinuteRecord();
            this.$api.fetch(fetchMinuteRecordApi);
        },
        fetchMinuteRecord(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/minutes/record/'+this.$router.history.current.params.id;
            return tempApi;
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
    }
}
</script>